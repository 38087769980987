
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'VCheckbox',
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change',
  },
})
export default class VCheckbox extends Vue {
  @Prop({ default: null }) private label!: string|number|null;
  @Prop({ default: null }) private value!: string|Record<string, unknown>|boolean|null;
  @Prop({ default: null }) private checked!: string[]|number[]|boolean|null|undefined;
  @Prop({ default: false }) private disabled!: boolean;
  @Prop({ default: false }) private danger!: boolean;
  @Prop({ default: false }) private oneline!: boolean;
  @Prop({ default: null }) private valueAttr!: string|null;
  @Prop({ default: null }) private id!: string|null;
  @Prop({ default: 0 }) private tabindex!: number|null;

  selected = this.checked

  get computedId (): string {
    return this.id || `rp-check-${Math.random()}`
  }

  get isChecked (): boolean {
    if (this.isBoolean) {
      return !!this.checked
    }

    return this.checkedValues.includes(this.storeValue)
  }

  get checkedValues (): any[] {
    const checked = this.checked as string[]|number[]

    return checked ? (checked as Array<string|number>).map((c: any) => this.getStoreValue(c)) : []
  }

  get storeValue (): any {
    return this.getStoreValue(this.value)
  }

  get isBoolean (): boolean {
    return typeof this.checked === 'boolean'
  }

  get attrs (): any {
    return this.$attrs
  }

  // eslint-disable-next-line
  getStoreValue (value: any): any {
    return this.valueAttr !== null ? value[this.valueAttr] : value
  }

  onClick (): any {
    if (this.disabled) {
      return
    }

    if (this.isBoolean) {
      return this.$emit('change', !this.checked)
    }

    // @ts-ignore: Can't recognize checked type
    let buffer = [...this.checked]

    if (this.isChecked) {
      // @ts-ignore: Can't recognize checked type
      buffer = this.checked.filter(el => this.getStoreValue(el) !== this.storeValue)
    } else {
      buffer.push(this.value as string|number)
    }

    this.$emit('change', buffer)
  }

  cssVars ():any {
    var storage = localStorage.getItem('full_data') || '{}'
    var full_data = JSON.parse(storage)

    return {
      '--button-primary-background-color': full_data['custom_primary_button_background_color'],
      '--button-secondary-background-color': full_data['custom_secondary_button_background_color'],
      '--button-primary-text-color': full_data['custom_primary_button_text_color'],
      '--button-secondary-text-color': full_data['custom_secondary_button_text_color'],
      '--button-primary-border-color': full_data['custom_primary_button_border_color'],
      '--button-secondary-border-color': full_data['custom_secondary_button_border_color'],
      '--custom-oval-steps-text-color': full_data['custom_oval_steps_text_color'],
      '--custom-background-color': full_data['custom_background_color'],
      '--custom-background-color2': full_data['custom_background_color2'],
      '--custom-text-color': full_data['custom_text_color'],
      '--custom-font': full_data['custom_font'],
      '--custom-menu-font': full_data['custom_menu_font'],
      '--custom-logo-url': full_data['custom_logo-url'],
      '--custom-logo-position-x': full_data['custom_logo_position_x'],
      '--custom-logo-position-y': full_data['custom_logo_position_y'],
      '--custom-form-input-invalid-color': full_data['custom_form_input_invalid_color'],
    }
  }
}
