
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PaymentDetailsType, PaymentMethodResponseType, CustomerDataType, FullCheckoutDataType } from '@/types/checkout'
import { PaymentMethod } from '@/constants/payment-method'
import VRadio from '@/components/VRadio.vue'
import PaymentMethodInvoice from '@/views/PaymentPage/PaymentMethodInvoice.vue'
import PaymentMethodDirectDebit from '@/views/PaymentPage/PaymentMethodDirectDebit.vue'
import PaymentMethodPrepayment from '@/views/PaymentPage/PaymentMethodPrepayment.vue'
import PaymentMethodInstallmentBankTransfer from '@/views/PaymentPage/PaymentMethodInstallmentBankTransfer.vue'
import PaymentMethodInstallmentDirectDebit from '@/views/PaymentPage/PaymentMethodInstallmentDirectDebit.vue'

@Component({
  name: 'StepPaymentMethod',
  components: {
    VRadio,
    PaymentMethodInvoice,
    PaymentMethodDirectDebit,
    PaymentMethodPrepayment,
    PaymentMethodInstallmentBankTransfer,
    PaymentMethodInstallmentDirectDebit,
  },
})
export default class StepPaymentMethod extends Vue {
  @Prop() private value!: PaymentMethodResponseType;
  @Prop() private customerData!: CustomerDataType;
  @Prop() private checkoutData!: FullCheckoutDataType;
  @Prop() private allowed_payments: any;

  editData: PaymentDetailsType = { ...this.value.payment_details } as PaymentDetailsType
  customer: CustomerDataType = { ...this.customerData } as CustomerDataType
  // paymentOption: PaymentMethod = this.customer?.default_payment as PaymentMethod || this.methods[0]
  paymentOption: any = this.getDefaultPayment() || this.methods[0];
  moto: any = 'false'
  full_data = ''

  backgroundcolorData = ''

  mounted (): void {
    var storage = localStorage.getItem('full_data') || '{}'
    this.full_data = JSON.parse(storage)
  }

  getComponentName (option: string): string {
    var strippedOption = option.replace(/_[0-9]/g, '')
    const mapping: Record<string, string> = {
      'INVOICE_BANK_TRANSFER': 'PaymentMethodInvoice',
      'SEPA_DIRECT_DEBIT': 'PaymentMethodDirectDebit',
      'PREPAYMENT_BANK_TRANSFER': 'PaymentMethodPrepayment',
      'INSTALLMENT_BANK_TRANSFER': 'PaymentMethodInstallmentBankTransfer',
      'INSTALLMENT_DIRECT_DEBIT': 'PaymentMethodInstallmentDirectDebit',
    }

    return mapping[strippedOption]
  }

  getDefaultPayment (): any|null|undefined {
    var methodNames: any = this.allowed_payments
    var found = false
    var methodsObj = this.value.allowed_methods
    var is_financing: any = 'is_financing'
    if (localStorage.getItem('payment_method') == null || typeof (localStorage.getItem('payment_method')) === 'undefined') {
      if (this.customerData.default_payment === 'SEPA_DIRECT_DEBIT') {
        localStorage.setItem('payment_method', PaymentMethod.SEPA_DIRECT_DEBIT)
        methodNames.forEach((methodName: any) => {
          if (methodName.search(PaymentMethod.SEPA_DIRECT_DEBIT.toString()) !== -1) {
            if (!found) {
              this.paymentOption = methodName
              this.emit()
              found = true
            }
          }
        })

        return this.paymentOption
      }

      if (this.customerData.default_payment === 'PREPAYMENT_BANK_TRANSFER') {
        localStorage.setItem('payment_method', PaymentMethod.PREPAYMENT_BANK_TRANSFER)
        methodNames.forEach((methodName: any) => {
          if (methodName.search(PaymentMethod.PREPAYMENT_BANK_TRANSFER.toString()) !== -1) {
            if (!found) {
              this.paymentOption = methodName
              this.emit()
              found = true
            }
          }
        })

        return this.paymentOption
      }

      if (this.customerData.default_payment === 'FINANCING_BANK_TRANSFER') {
        localStorage.setItem('payment_method', PaymentMethod.INSTALLMENT_BANK_TRANSFER)
        methodNames.forEach((methodName: any) => {
          if (methodName.search(PaymentMethod.INSTALLMENT_BANK_TRANSFER.toString()) !== -1) {
            if (typeof (methodsObj[methodName][is_financing]) !== 'undefined' && methodsObj[methodName][is_financing].toString() === 'true') {
              if (!found) {
                this.paymentOption = methodName
                this.emit()
                found = true
              }
            }
          }
        })

        return this.paymentOption
      }

      if (this.customerData.default_payment === 'INSTALLMENT_BANK_TRANSFER') {
        localStorage.setItem('payment_method', PaymentMethod.INSTALLMENT_BANK_TRANSFER)
        methodNames.forEach((methodName: any) => {
          if (methodName.search(PaymentMethod.INSTALLMENT_BANK_TRANSFER.toString()) !== -1) {
            if (typeof (methodsObj[methodName][is_financing]) !== 'undefined' && methodsObj[methodName][is_financing].toString() === 'false') {
              if (!found) {
                this.paymentOption = methodName
                this.emit()
                found = true
              }
            }
          }
        })

        return this.paymentOption
      }

      if (this.customerData.default_payment === 'FINANCING_DIRECT_DEBIT') {
        localStorage.setItem('payment_method', PaymentMethod.INSTALLMENT_DIRECT_DEBIT)
        methodNames.forEach((methodName: any) => {
          if (methodName.search(PaymentMethod.INSTALLMENT_DIRECT_DEBIT.toString()) !== -1) {
            if (typeof (methodsObj[methodName][is_financing]) !== 'undefined' && methodsObj[methodName][is_financing].toString() === 'true') {
              if (!found) {
                this.paymentOption = methodName
                this.emit()
                found = true
              }
            }
          }
        })

        return this.paymentOption
      }

      if (this.customerData.default_payment === 'INSTALLMENT_DIRECT_DEBIT') {
        localStorage.setItem('payment_method', PaymentMethod.INSTALLMENT_DIRECT_DEBIT)
        methodNames.forEach((methodName: any) => {
          if (methodName.search(PaymentMethod.INSTALLMENT_DIRECT_DEBIT.toString()) !== -1) {
            if (typeof (methodsObj[methodName][is_financing]) !== 'undefined' && methodsObj[methodName][is_financing].toString() === 'false') {
              if (!found) {
                this.paymentOption = methodName
                this.emit()
                found = true
              }
            }
          }
        })

        return this.paymentOption
      }

      if (this.customerData.default_payment === 'INVOICE_BANK_TRANSFER') {
        localStorage.setItem('payment_method', PaymentMethod.INVOICE_BANK_TRANSFER)
        methodNames.forEach((methodName: any) => {
          if (methodName.search(PaymentMethod.INVOICE_BANK_TRANSFER.toString()) !== -1) {
            if (!found) {
              this.paymentOption = methodName
              this.emit()
              found = true
            }
          }
        })

        return this.paymentOption
      }

      return this.value.payment_details?.payment_method
    }

    return this.value.payment_details?.payment_method
  }

  getLabel (item : string, entry : any) :string {
    var paymentMethod = item
    var methodsObj = this.value.allowed_methods
    var is_financing: any = 'is_financing'
    if (typeof (methodsObj[entry][is_financing]) !== 'undefined' && methodsObj[entry][is_financing].toString() === 'true') {
      paymentMethod = paymentMethod.replace('Ratenzahlung', '0% Finanzierung')
      paymentMethod = paymentMethod.replace('Installments', '0% Financing')
      paymentMethod = paymentMethod.replace('Versement', '0% Financement')
    }

    return paymentMethod
  }

  getProfile (item : any) :string {
    var num = item.match(/\d/g).join('')

    return num
  }

  get methods () :any {
    var hasShipping = localStorage.getItem('hasShipping') ?? 'false'
    var shipping = localStorage.getItem('shipping')
    var shippingparsed : any
    var billingparsed : any
    if (shipping) {
      shippingparsed = JSON.parse(shipping)
    }
    if (shippingparsed && shippingparsed.city === '') {
      shippingparsed = false
    }
    var billing = localStorage.getItem('billing') ?? '{}'
    billingparsed = JSON.parse(billing)

    var methodsObj = this.value.allowed_methods
    const allowed: any[] = []

    var methodNames: any = Object.keys(methodsObj)
    var b2b_allowed: any = 'b2b_allowed'
    var delivery_adress_allowed: any = 'delivery_address_allowed'
    var country_code_billing: any = 'country_code_billing'
    var country_code_delivery: any = 'country_code_delivery'
    var is_moto: any = 'is_moto'

    methodNames.forEach((methodName: any) => {
      this.moto = localStorage.getItem('motoGlobal')
      var removeMethod = false
      if (methodsObj[methodName][b2b_allowed].toString() === 'false' && this.value.customer_type !== 'B2C') {
        removeMethod = true
      }

      if (methodsObj[methodName][delivery_adress_allowed].toString() === 'false' && hasShipping.toString() === 'true') {
        removeMethod = true
      }

      if (methodsObj[methodName][is_moto].toString() === 'false' && this.moto === 'true') {
        removeMethod = true
      }

      var bilcountries = methodsObj[methodName][country_code_billing].split(',')
      if (typeof (billingparsed.country_code) !== 'undefined') {
        if (!bilcountries.includes(billingparsed.country_code.toString())) {
          removeMethod = true
        }
      }

      var delcountries = methodsObj[methodName][country_code_delivery].split(',')
      if (hasShipping === 'true' && !delcountries.includes(shippingparsed.country_code.toString())) {
        removeMethod = true
      }

      if (!removeMethod) {
        allowed.push(methodName)
      }

      if (typeof (this.paymentOption) !== 'undefined' && methodsObj[methodName][is_moto].toString() === 'true' && this.paymentOption.toString() === methodName.toString()) {
        this.$emit('update', true)
      } else if (typeof (this.paymentOption) !== 'undefined' && methodsObj[methodName][is_moto].toString() === 'false' && this.paymentOption.toString() === methodName.toString()) {
        this.$emit('update', false)
      }
    })
    // return orderedMethods.filter(entry => (methods || []).includes(entry))

    return allowed
  }

  get paymentOptionModel (): PaymentMethod {
    return this.paymentOption
  }

  set paymentOptionModel (option: PaymentMethod) {
    this.paymentOption = option
    this.emit()
  }

  get editDataModel (): PaymentDetailsType {
    return this.editData
  }

  set editDataModel (data: PaymentDetailsType) {
    this.editData = data
    this.emit()
  }

  emit (): void {
    this.$emit('input', {
      ...this.value,
      payment_details: {
        ...this.editData,
        payment_method: this.paymentOption,
      },
    } as PaymentMethodResponseType)
  }
}
