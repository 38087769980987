var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "basket" }, [
    _c("div", { staticClass: "gray-container" }, [
      _c("h3", { staticClass: "title" }, [
        _vm._v(" " + _vm._s(_vm.$t("basket_title")) + " "),
        _c("span", [
          _vm._v(
            "(" +
              _vm._s(
                _vm.$tc("basket_items_quantity", _vm.items.length, {
                  quantity: _vm.itemsQuantity,
                })
              ) +
              ")"
          ),
        ]),
      ]),
      _c("div", { staticClass: "basket-table" }, [
        _c("div", { staticClass: "basket-header" }, [
          _c("div", [_vm._v(_vm._s(_vm.$t("basket_header_name")))]),
          _c("div", { staticClass: "to-right" }, [
            _vm._v(_vm._s(_vm.$t("basket_header_quantity"))),
          ]),
          _c("div", { staticClass: "to-right" }, [
            _vm._v(_vm._s(_vm.$t("basket_header_amount"))),
          ]),
          _c("div", { staticClass: "to-right" }, [
            _vm._v(_vm._s(_vm.$t("basket_header_tax"))),
          ]),
          _c("div", { staticClass: "to-right" }, [
            _vm._v(_vm._s(_vm.$t("basket_header_total"))),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "basket-body" },
          _vm._l(_vm.items, function (item) {
            return _c(
              "div",
              {
                key: `${item.article_number}${item.unique_article_number}`,
                staticClass: "basket-row",
              },
              [
                _c("div", [_vm._v(_vm._s(item.description))]),
                _c("div", { staticClass: "to-right" }, [
                  _vm._v(_vm._s(item.quantity)),
                ]),
                _c("div", { staticClass: "to-right" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$_.money(
                        _vm.estimateArticle(item),
                        _vm.basket.currency
                      )
                    )
                  ),
                ]),
                _c("div", { staticClass: "to-right" }, [
                  _vm._v(_vm._s(item.tax_rate) + " %"),
                ]),
                _c("div", { staticClass: "to-right" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$_.money(_vm.estimateTotal(item), _vm.basket.currency)
                    )
                  ),
                ]),
              ]
            )
          }),
          0
        ),
        _vm.shipping || _vm.discount
          ? _c("div", { staticClass: "separator hide-if-small" })
          : _vm._e(),
        _vm.shipping
          ? _c("div", { staticClass: "basket-row hide-if-small" }, [
              _c("div", [_vm._v(_vm._s(_vm.shipping.description))]),
              _c("div", { staticClass: "to-right" }),
              _c("div", { staticClass: "to-right" }),
              _c("div"),
              _c("div", { staticClass: "to-right" }, [
                _vm._v(
                  _vm._s(
                    _vm.$_.money(
                      _vm.shipping.unit_price_gross,
                      _vm.basket.currency
                    )
                  )
                ),
              ]),
            ])
          : _vm._e(),
        _vm.discount
          ? _c("div", { staticClass: "basket-row hide-if-small" }, [
              _c("div", [_vm._v(_vm._s(_vm.discount.description))]),
              _c("div", { staticClass: "to-right" }),
              _c("div", { staticClass: "to-right" }),
              _c("div"),
              _c("div", { staticClass: "to-right" }, [
                _vm._v(
                  _vm._s(
                    _vm.$_.money(
                      _vm.discount.unit_price_gross,
                      _vm.basket.currency
                    )
                  )
                ),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "separator hide-if-small" }),
        _c("div", { staticClass: "total-row hide-if-small" }, [
          _c("div"),
          _c("div"),
          _c("div", { staticClass: "to-right" }, [
            _vm._v(_vm._s(_vm.$_.money(_vm.basket.total, _vm.basket.currency))),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "gray-container hide-if-large" }, [
      _c("div", { staticClass: "totals-table" }, [
        _vm._m(0),
        _vm.shipping
          ? _c("div", { staticClass: "row" }, [
              _c("div", [_vm._v(_vm._s(_vm.shipping.description))]),
              _c("div", { staticClass: "to-right" }, [
                _vm._v(
                  _vm._s(
                    _vm.$_.money(
                      _vm.shipping.unit_price_gross,
                      _vm.basket.currency
                    )
                  )
                ),
              ]),
            ])
          : _vm._e(),
        _vm.discount
          ? _c("div", { staticClass: "row" }, [
              _c("div", [_vm._v(_vm._s(_vm.discount.description))]),
              _c("div", { staticClass: "to-right" }, [
                _vm._v(
                  _vm._s(
                    _vm.$_.money(
                      _vm.discount.unit_price_gross,
                      _vm.basket.currency
                    )
                  )
                ),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "separator" }),
        _c("div", { staticClass: "row" }, [
          _c("h3", [_vm._v(_vm._s(_vm.$t("basket_total")))]),
          _c("div", { staticClass: "to-right" }, [
            _c("b", [
              _vm._v(
                _vm._s(_vm.$_.money(_vm.basket.total, _vm.basket.currency))
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row" }, [_c("div"), _c("div")])
  },
]
render._withStripped = true

export { render, staticRenderFns }