import {
  CustomerDataType,
  CheckoutMetaType,
  CheckoutResponseType,
  PaymentMethodResponseType,
} from '@/types/checkout'
import { getCheckoutData } from '@/api/checkout'
import { CheckoutState } from '@/constants/checkout-state'

export enum Mutations {
  SET_CHECKOUT_DATA = 'SET_CHECKOUT_DATA',
  CLEANUP_INVALID = 'CLEANUP_INVALID',
  ADD_INVALID = 'ADD_INVALID',
  REMOVE_INVALID = 'REMOVE_INVALID',
}

export enum Actions {
  GET_STEP = 'GET_STEP',
}

const state: {
  data: CustomerDataType|PaymentMethodResponseType|null,
  meta: CheckoutMetaType|null,
  invalidInputs: string[],
} = {
  data: null,
  meta: null,
  invalidInputs: [],
}

const mutations = {
  [Mutations.SET_CHECKOUT_DATA]: (state: State, data: CheckoutResponseType): void => {
    state.data = data.data
    state.meta = data.meta
  },
  [Mutations.ADD_INVALID]: (state: State, form: string): void => {
    state.invalidInputs = [...new Set([...state.invalidInputs, form])]
  },
  [Mutations.REMOVE_INVALID]: (state: State, form: string): void => {
    state.invalidInputs = state.invalidInputs.filter(name => name !== form)
  },
  [Mutations.CLEANUP_INVALID]: (state: State): void => {
    state.invalidInputs = []
  },
}

const actions = {
  [Actions.GET_STEP]: ({ commit }: { commit: any }, token: string): Promise<CheckoutResponseType> => {
    console.log('GetCheckoutData token is: ' + token)

    return getCheckoutData(token)
      .then(({ data }: {data: CheckoutResponseType}) => {
        console.log('Before Commit TOken is' + token)
        commit(Mutations.SET_CHECKOUT_DATA, data)
        console.log('Checkout.ts Data:' + JSON.stringify(data))

        return data
      })
  },
}

const getters = {
  data: (state: State): CustomerDataType|PaymentMethodResponseType|null => state.data,
  meta: (state: State): CheckoutMetaType|null => state.meta,
  step: (state: State): CheckoutState|null => state.meta ? state.meta.state : null,
  allValid: (state: State): boolean => state.invalidInputs.length === 0,
}

export type State = typeof state
export type Getters = typeof getters

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
