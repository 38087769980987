
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'VRadio',
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change',
  },
})
export default class VRadio extends Vue {
  @Prop({ default: null }) private label!: string|number|null;
  @Prop({ default: null }) private value!: string|boolean|null;
  @Prop({ default: null }) private checked!: boolean|null|undefined;
  @Prop({ default: false }) private disabled!: boolean;
  @Prop({ default: false }) private danger!: boolean;
  @Prop({ default: null }) private id!: string|null;
  @Prop({ default: 0 }) private tabindex!: number|null;

  selected = this.checked

  get computedId (): string {
    return this.id || `rp-check-${Math.random()}`
  }

  get isChecked (): boolean {
    return this.value === this.checked
  }

  get attrs (): any {
    return this.$attrs
  }

  emit (): void {
    this.$emit('change', this.value)
  }

  onClick (): any {
    if (this.disabled) {
      return
    }

    this.emit()
  }

  onSelect (): void {
    this.emit()
  }

  cssVars ():any {
    var storage = localStorage.getItem('full_data') || '{}'
    var full_data = JSON.parse(storage)

    return {
      '--button-primary-background-color': full_data['custom_primary_button_background_color'],
      '--button-secondary-background-color': full_data['custom_secondary_button_background_color'],
      '--button-primary-text-color': full_data['custom_primary_button_text_color'],
      '--button-secondary-text-color': full_data['custom_secondary_button_text_color'],
      '--button-primary-border-color': full_data['custom_primary_button_border_color'],
      '--button-secondary-border-color': full_data['custom_secondary_button_border_color'],
      '--custom-oval-steps-text-color': full_data['custom_oval_steps_text_color'],
      '--custom-background-color': full_data['custom_background_color'],
      '--custom-background-color2': full_data['custom_background_color2'],
      '--custom-text-color': full_data['custom_text_color'],
      '--custom-font': full_data['custom_font'],
      '--custom-menu-font': full_data['custom_menu_font'],
      '--custom-logo-url': full_data['custom_logo-url'],
      '--custom-logo-position-x': full_data['custom_logo_position_x'],
      '--custom-logo-position-y': full_data['custom_logo_position_y'],
    }
  }
}
