var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "input-message", class: _vm.type, style: _vm.cssVars },
    [_vm._v(" " + _vm._s(_vm.hint) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }