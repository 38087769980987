
import { mask } from 'vue-the-mask'
import { Component, Prop, Vue } from 'vue-property-decorator'
import VButton from '@/components/VButton.vue'

@Component({
  name: 'VInput',
  components: { VButton },
  inheritAttrs: false,
  directives: {
    mask (el, binding) {
      if (binding.value !== null) {
        // @ts-ignore: Doesn't require more params
        return mask.call(this, el, binding)
      }
    },
  },
})
export default class VInput extends Vue {
  @Prop({ default: null }) private label!: string|null;
  @Prop({ default: '' }) private value!: string|number;
  @Prop({ default: false }) private disabled!: boolean;
  @Prop({ default: false }) private danger!: boolean;
  @Prop({ default: null }) private mask!: string|null;
  @Prop({ default: false }) private placeholder!: string|number;
  @Prop({ default: 0 }) private tabindex!: number;

  focused = false

  get attrs (): any {
    return this.$attrs
  }

  get listeners (): any {
    // eslint-disable-next-line
    const { input, ...listeners } = this.$listeners

    return listeners
  }

  get placeholderText (): any {
    if (!this.focused) {
      return ''
    }

    return this.placeholder
  }

  cssVars ():any {
    var storage = localStorage.getItem('full_data') || '{}'
    var full_data = JSON.parse(storage)

    return {
      '--button-primary-background-color': full_data['custom_primary_button_background_color'],
      '--button-secondary-background-color': full_data['custom_secondary_button_background_color'],
      '--button-primary-text-color': full_data['custom_primary_button_text_color'],
      '--button-secondary-text-color': full_data['custom_secondary_button_text_color'],
      '--button-primary-border-color': full_data['custom_primary_button_border_color'],
      '--button-secondary-border-color': full_data['custom_secondary_button_border_color'],
      '--custom-oval-steps-text-color': full_data['custom_oval_steps_text_color'],
      '--custom-background-color': full_data['custom_background_color'],
      '--custom-background-color2': full_data['custom_background_color2'],
      '--custom-text-color': full_data['custom_text_color'],
      '--custom-font': full_data['custom_font'],
      '--custom-menu-font': full_data['custom_menu_font'],
      '--custom-logo-url': full_data['custom_logo-url'],
      '--custom-logo-position-x': full_data['custom_logo_position_x'],
      '--custom-logo-position-y': full_data['custom_logo_position_y'],
      '--custom-form-input-font': full_data['custom_form_input_font'],
      '--custom-form-label-font': full_data['custom_form_label_font'],
      '--custom-form-input-invalid-color': full_data['custom_form_input_invalid_color'],

    }
  }
}
