var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "step-landing" }, [
    _c(
      "div",
      { staticClass: "basket-block" },
      [
        _c("Basket", {
          attrs: {
            basket: _vm.basket,
            "backgroundcolor2-data": _vm.backgroundcolor2Data,
          },
        }),
        _c("BuyerData", { attrs: { data: _vm.checkoutData } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }