var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    {
      staticClass: "v-button",
      class: { [_vm.type]: true, disabled: _vm.disabled },
      style: _vm.cssVars(),
      attrs: { disabled: _vm.disabled, "aria-disabled": _vm.disabled },
      on: { click: _vm.onClick },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }