
import { Mutation } from '@/decorators/Mutation'
import { Mutations } from '@/store/modules/checkout'
import { MutationType } from '@/types/general'
import FormGroup from '@/components/FormGroup.vue'
import VSelect from '@/components/VSelect.vue'
import VInput from '@/components/VInput.vue'
import { Gender, Type } from '@/constants/customer'
import { isDate } from '@/services/validator'
import { CustomerType } from '@/types/customer'
import { mixins } from 'vue-class-component'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { Uid } from '@/mixins/Uid'
import { validationMixin } from 'vuelidate'
import { email, required, requiredIf } from 'vuelidate/lib/validators'

@Component({
  name: 'CustomerForm',
  components: { VInput, VSelect, FormGroup },
  mixins: [validationMixin],
  validations: {
    data: {
      first_name: { required },
      last_name: { required },
      phone: { required },
      email: { required, email },
      date_of_birth: {
        required: requiredIf(model => {
          return !model.company_name
        }),
        date: isDate(),
      },
    },
  },
})
export default class CustomerForm extends mixins(Uid) {
  @Prop() private value!: CustomerType;

  @Mutation(`checkout/${Mutations.ADD_INVALID}`) addInvalid!: MutationType<string, void>
  @Mutation(`checkout/${Mutations.REMOVE_INVALID}`) removeInvalid!: MutationType<string, void>

  data = {
    ...this.emptyData(),
    ...this.value,
  } as CustomerType

  mounted (): void {
    this.handleValid()
  }

  getDisabled (): boolean {
    var full_data = JSON.parse(localStorage.getItem('full_data'))
    if (full_data['edit_customer'] === false) {
      console.log('full_data["edit_customer"] is true')

      return true
    } else {
      console.log('full_data["edit_customer"] is false')

      return false
    }
  }

  get genders (): { title: any, value: Gender }[] {
    return [
      { title: this.$t('salutation_UNKNOWN'), value: Gender.UNKNOWN },
      { title: this.$t('salutation_MALE'), value: Gender.MALE },
      { title: this.$t('salutation_FEMALE'), value: Gender.FEMALE },
    ]
  }

  get hasCompanyName (): boolean {
    return !!this.data.company_name
  }

  get mask (): string {
    console.log('mask is:' + this.data.language.toLowerCase() === 'en')

    return (this.data.language.toLowerCase() === 'en') ? '####-##-##' : '##.##.####'
  }

  emptyData (): CustomerType {
    console.log('filling with empty data:');
    return {
      type: Type.B2C,
      first_name: '',
      last_name: '',
      date_of_birth: '',
      phone: '',
      email: '',
      gender: Gender.UNKNOWN,
      company_name: '',
      vat_id: '',
      language: 'en',
    }
  }

  get genderType (): Gender {
    return [Gender.MALE, Gender.FEMALE].includes(this.data.gender) ? this.data.gender : Gender.UNKNOWN
  }

  set genderType (value: Gender) {
    this.data.gender = value
  }

  @Watch('data', { deep: true })
  onDataChange (data: CustomerType): void {
    this.handleValid()

    data = { ...data }
    if (!data.company_name) {
      data.vat_id = ''
    }
    data.type = !data.company_name ? Type.B2C : Type.B2B

    this.$emit('input', data)
  }

  handleValid (): void {
    this.$v.$invalid ? this.addInvalid('customer' + this.uid) : this.removeInvalid('customer' + this.uid)
  }

  beforeDestroy (): void {
    this.removeInvalid('customer' + this.uid)
  }
}
