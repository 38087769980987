
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'StepsLine' })
export default class StepsLine extends Vue {
  @Prop({
    default: () => [],
  }) private steps!: string[];

  @Prop({ default: 1 }) private current!: number;

  cssVars ():any {
    var storage = localStorage.getItem('full_data') || '{}'
    var full_data = JSON.parse(storage)

    return {
      '--button-primary-background-color': full_data['custom_primary_button_background_color'],
      '--button-secondary-background-color': full_data['custom_secondary_button_background_color'],
      '--button-primary-text-color': full_data['custom_primary_button_text_color'],
      '--button-secondary-text-color': full_data['custom_secondary_button_text_color'],
      '--button-primary-border-color': full_data['custom_primary_button_border_color'],
      '--button-secondary-border-color': full_data['custom_secondary_button_border_color'],
      '--custom-steps-line-color': full_data['custom_steps_line_color'],
      '--custom-steps-unseen-line-color': full_data['custom_steps_unseen_line_color'],
      '--custom-steps-active-color': full_data['custom_steps_active_color'],
      '--custom-steps-active-text-color': full_data['custom_steps_active_text_color'],
      '--custom-steps-seen-color': full_data['custom_steps_seen_color'],
      '--custom-steps-seen-text-color': full_data['custom_steps_seen_text_color'],
      '--custom-steps-seen-border-color': full_data['custom_steps_seen_border_color'],
      '--custom-steps-unseen-color': full_data['custom_steps_unseen_color'],
      '--custom-steps-unseen-text-color': full_data['custom_steps_unseen_text_color'],
      '--custom-steps-unseen-border-color': full_data['custom_steps_unseen_border_color'],
      '--custom-background-color': full_data['custom_background_color'],
      '--custom-background-color2': full_data['custom_background_color2'],
      '--custom-text-color': full_data['custom_text_color'],
      '--custom-font': full_data['custom_font'],
      '--custom-steps-font': full_data['custom_steps_font'],
      '--custom-steps-digit-font': full_data['custom_steps_digit_font'],
      '--custom-steps-text-color': full_data['custom_steps_text_color'],
      '--custom-logo-url': full_data['custom_logo-url'],
      '--custom-logo-position-x': full_data['custom_logo_position_x'],
      '--custom-logo-position-y': full_data['custom_logo_position_y'],
    }
  }
}
