
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'VButton',
})
export default class ActionBar extends Vue {
  @Prop({ default: '' }) private type!: string
  @Prop({ default: false }) private disabled!: boolean

  cssVars () {
    var full_data = JSON.parse(localStorage.getItem('full_data'))
    console.log('CSSVars:')
    console.log(full_data['custom_primary_button_background_color'])
    console.log(full_data['custom_secondary_button_background_color'])

    return {
      '--button-primary-bg-color': full_data['custom_primary_button_background_color'],
      '--button-secondary-bg-color': full_data['custom_secondary_button_background_color'],
      '--button-primary-text-color': full_data['custom_primary_button_text_color'],
      '--button-secondary-text-color': full_data['custom_secondary_button_text_color'],
    }
  }

  onClick (e: Event): void {
    if (this.disabled) {
      console.log('Button is disabled')

      return
    }
    console.log('Button is enabled')

    this.$emit('click', e)
  }
}
