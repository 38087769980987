
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PaymentDetailsType, PaymentMethodResponseType } from '@/types/checkout'
import InstallmentMonthsForm from '@/views/PaymentPage/InstallmentMonthsForm.vue'
import InstallmentCalculations from '@/views/PaymentPage/InstallmentCalculations.vue'

@Component({
  name: 'PaymentMethodInstallmentBankTransfer',
  components: { InstallmentCalculations, InstallmentMonthsForm },
})
export default class PaymentMethodInstallmentBankTransfer extends Vue {
  @Prop() private value!: PaymentDetailsType;
  @Prop() private response!: PaymentMethodResponseType;
  @Prop() private backgroundcolorData!: string;
  @Prop() private profile!: string;

  mounted (): void {
    this.$emit('input', {})
    console.log('mounted profile:')
    console.log(this.profile)
    console.log('installment config:')
    console.log(JSON.stringify(this.response.installment_config))
  }

  selectMonths (months: number): void {
    this.$emit('input', {
      ...this.value,
      installment: {
        total_months: months,
      },
    })
  }

  getOption (item : any) {
    console.log('item:')
    console.log(item)

    return item[this.profile]['INSTALLMENT_BANK_TRANSFER_'].allowed_monthly_durations
  }
}
