
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'StepsLine' })
class StepsLine extends Vue {
  @Prop({
    default: () => [],
  }) private steps!: string[];

  @Prop({ default: 1 }) private current!: number;

  @Prop() private backgroundcolorData!: string;
  @Prop() private backgroundcolor2Data!: string;

  @Prop() private customMenuFontData!: string;

  customMenuFont = ''
  backgroundcolor2 = ''
  backgroundcolor = ''

  mounted (): void {
    this.customMenuFont = this.customMenuFontData
    this.backgroundcolor2 = this.backgroundcolor2Data
    this.backgroundcolor = this.backgroundcolorData
    console.log('this.customMenuFont:' + this.customMenuFontData)
    console.log('Steps Line BG Color 2:' + this.backgroundcolor2Data)
    console.log('Steps Line BG Color :' + this.backgroundcolorData)
  }
}

const __default__ = StepsLine
import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "0d38a38a-backgroundcolorData": (_vm.backgroundcolorData),
  "0d38a38a-customMenuFont": (_vm.customMenuFont)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__