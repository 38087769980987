import { EventBus } from '@/services/event-bus'
import { i18n } from '@/services/localizator'
import { AxiosError, AxiosResponse } from 'axios'

export enum NotificationType {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export interface NotificationPayloadType extends AxiosError {
  text: string,
  response: AxiosResponse,
  details: string|string[],
}

export interface ErrorResponseDataType {
  info: string,
  source: string,
}

export interface NotificationParamsType {
  timeout: number,
}

let interval:number
let url:string

const getMessage = (payload: NotificationPayloadType|string): string => {
  if (typeof payload === 'string') {
    return payload
  } else if (payload.text) {
    return payload.text
  } else if (payload?.response?.data?.message) {
    const error = payload.response.data.message
    if (typeof payload.response.status !== 'undefined' && payload.response.status === 406) {
      const forwardButton = <HTMLButtonElement>document.getElementById('forwardButton')
      const backButton = <HTMLButtonElement>document.getElementById('backButton')
      if (typeof forwardButton !== 'undefined' && forwardButton !== null) {
        forwardButton.disabled = true
      }

      if (typeof backButton !== 'undefined' && backButton !== null) {
        backButton.disabled = true
      } else {
      }

      interval = setInterval(myURL, parseInt(payload.response.data.error_redirect_delay) * 1000)
      url = payload.response.data.redirect_url

      return ''
    }

    return i18n.t(error) as string
  }

  return i18n.t('general_error_message') as string
}

const getDetails = (payload: NotificationPayloadType|string): string => {
  if (typeof payload === 'string') {
    return ''
  }

  if (typeof (payload) === 'undefined') {
    return ''
  }

  if (typeof (payload.response) === 'undefined') {
    return ''
  }

  if (typeof (payload.response.status) === 'undefined') {
    return ''
  }

  if (payload.response.status === 406) {
    payload.details = payload.response.data.details
  }
  const details = typeof payload.details === 'string' ? [payload.details] : []

  /* if (payload?.response?.data?.errors) {
    payload.response.data.errors.forEach((error: ErrorResponseDataType) => details.push(i18n.t(error.info) as string))
  }
  */

  return details.join('<br>')
}

function myURL () {
  document.location.href = url
  clearInterval(interval)
}

export const notification = {
  add (payload: NotificationPayloadType|string, type: NotificationType|undefined, icon: string|null = null, params: NotificationParamsType|null): void {
    const message = getMessage(payload)
    const details = getDetails(payload)

    params = { timeout: 50000, ...(params || {}) }
    EventBus.emit('notification:create', {
      notification: {
        message,
        details,
        type,
        icon,
      },
      params,
    })
  },

  error (text: NotificationPayloadType|string, params: NotificationParamsType|null = null): void {
    console.log('error notify queue')
    console.log(text)
    notification.add(text, NotificationType.ERROR, 'error', params)
  },
  success (text: NotificationPayloadType|string, params: NotificationParamsType|null = null): void {
    notification.add(text, NotificationType.SUCCESS, 'check-circle', params)
  },
  warning (text: NotificationPayloadType|string, params: NotificationParamsType|null = null): void {
    notification.add(text, NotificationType.WARNING, 'warning', params)
  },
  info (text: NotificationPayloadType|string, params: NotificationParamsType|null = null): void {
    notification.add(text, NotificationType.INFO, 'info', params)
  },
}
