
import { Component, Prop, Vue } from 'vue-property-decorator'
import VInput from '@/components/VInput.vue'
import VCheckbox from '@/components/VCheckbox.vue'
import FormGroup from '@/components/FormGroup.vue'
import VSelect from '@/components/VSelect.vue'
import { OptionType } from '@/types/forms'

@Component({
  name: 'InstallmentMonthsForm',
  components: {
    VSelect,
    FormGroup,
    VCheckbox,
    VInput,
  },
})
export default class InstallmentMonthsForm extends Vue {
  @Prop() private value!: number
  @Prop() private options!: number[]

  get months (): number {
    return this.value
  }

  set months (months: number) {
    this.$emit('input', months)
  }

  get preparedOptions (): OptionType[] {
    var options = this.options.map(entry => ({
      value: entry,
      title: this.$tc('installment_parts_label', entry,
        {
          parts: entry.toString().split('_')[0],
          profile: entry.toString().split('_')[1],
        }),
    }))

    return options
  }
}
