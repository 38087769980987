
import BuyerData from '@/views/PaymentPage/BuyerData.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ActionBar from '@/components/ActionBar.vue'
import MerchantLogo from '@/components/MerchantLogo.vue'
import Basket from '@/components/Basket.vue'
import Address from '@/views/PaymentPage/Address.vue'
import { BasketType, CustomerDataType } from '@/types/checkout'

@Component({
  name: 'StepLanding',
  components: {
    BuyerData,
    Address,
    Basket,
    MerchantLogo,
    ActionBar,
  },
})
export default class StepLanding extends Vue {
  @Prop() private checkoutData!: CustomerDataType;
  @Prop() private backgroundcolorData!: string;

  get backgroundcolor2Data (): string|null {
    return this.checkoutData.custom_background_color2
  }

  get basket (): BasketType|null|undefined {
    return this.checkoutData.basket
  }
}
