import { tokenProvider } from '@/services/token-provider'
import { AxiosInstance, AxiosRequestConfig } from 'axios'

export const applyRequestInterceptor = (http: AxiosInstance): void => {
  http.interceptors.request.use((request: AxiosRequestConfig) => {
    request.headers.common['X-Requested-With'] = 'XMLHttpRequest'

    // try getting token from local Storage
    let token = tokenProvider.getToken()

    if (token) {
      request.headers.common['Authorization'] = `Bearer ${token}`
      console.log('token from header' + token)
    } else {
      token = localStorage.getItem('token')
      request.headers.common['Authorization'] = `Bearer ${token}`
      console.log('token from storage' + token)
    }

    return request
  })
}
