
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PaymentDetailsType, PaymentMethodResponseType } from '@/types/checkout'
import InstallmentMonthsForm from '@/views/PaymentPage/InstallmentMonthsForm.vue'
import { WithRoute } from '@/types/integrations'
import BankDetailsForm from '@/views/PaymentPage/BankDetailsForm.vue'
import InstallmentCalculations from '@/views/PaymentPage/InstallmentCalculations.vue'

@Component({
  name: 'PaymentMethodInstallmentDirectDebit',
  components: { InstallmentCalculations, BankDetailsForm, InstallmentMonthsForm },
})
export default class PaymentMethodInstallmentDirectDebit extends Vue implements WithRoute {
  @Prop() private value!: PaymentDetailsType;
  @Prop() private response!: PaymentMethodResponseType;
  @Prop() private profile!: string;

  month = '0';

  selectMonths (months: string): void {
    // console.log('selected Month Emit')
    // console.log(this.value)
    this.month = months
    this.$emit('input', {
      ...this.value,
      installment: {
        total_months: parseInt(months, 10),
      },
    })
  }

  get bankDetails (): PaymentDetailsType {
    return this.value
  }

  set bankDetails (details: PaymentDetailsType) {
    console.log('BankDetails EMit:')
    console.log(details)
    this.$emit('input', {
      ...details,
      installment: {
        total_months: parseInt(this.month, 10),
      },
    })
  }

  getOption (item : any) {
    console.log('item:')
    console.log(item)
    console.log(item[this.profile].allowed_monthly_durations)

    return item[this.profile]['INSTALLMENT_DIRECT_DEBIT_'].allowed_monthly_durations
  }

  mounted (): void {
    console.log('Installment on Mounted' + this.month)
    console.log('Trigger SelectMonth')
    this.selectMonths(this.month)
    console.log('mounted profile:')
    console.log(this.profile)
    console.log('installment config:')
    console.log(JSON.stringify(this.response.installment_config))
  }
}
