
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  BasketItemType,
  BasketSpecialItemType,
  BasketType,
} from '@/types/checkout'
import { includedTax } from '@/services/tax-calculator'

@Component({
  name: 'Basket',
})
class Basket extends Vue {
  @Prop({ default: () => [] }) private basket!: BasketType;
  @Prop() private backgroundcolorData!: string;
  @Prop() private backgroundcolor2Data!: string;

  backgroundcolor2 = this.backgroundcolor2Data

  mounted (): void {
    if (typeof this.backgroundcolorData !== 'undefined') {
      console.log('Basket data:' + this.backgroundcolorData)
    }
    console.log('Basket backgroundcolor2Data:' + this.backgroundcolor2Data)
    console.log('Basket backgroundcolor2:' + this.backgroundcolor2)
  }

  get items (): BasketItemType[] {
    return this.basket?.items || []
  }

  get discount (): BasketSpecialItemType|null {
    return this.basket.discount || null
  }

  get shipping (): BasketSpecialItemType|null {
    return this.basket.shipping || null
  }

  get itemsQuantity (): number {
    return this.items.reduce((result, item) => result + item.quantity, 0)
  }

  estimateTax (amount: number, rate: number): number {
    return includedTax(amount, rate)
  }

  estimateTotal (item: BasketItemType): number {
    if (typeof item.discount === 'undefined') {
      item.discount = 0
    }

    return item.quantity * (item.unit_price_gross + item.discount)
  }

  estimateArticle (item: BasketItemType): number {
    if (typeof item.discount === 'undefined') {
      item.discount = 0
    }

    return item.unit_price_gross + item.discount
  }
}

const __default__ = Basket
import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "17500cf6-backgroundcolor2": (_vm.backgroundcolor2),
  "17500cf6-backgroundcolor2Data": (_vm.backgroundcolor2Data)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__