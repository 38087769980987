
import { EventBus } from '@/services/event-bus'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { confirmPayment, goNextStep, goPreviousStep } from '@/api/checkout'
import { CheckoutState } from '@/constants/checkout-state'
import { WithRoute } from '@/types/integrations'
import {
  CheckoutMetaType,
  CheckoutResponseType,
  CustomerDataType,
  PaymentMethodResponseType,
} from '@/types/checkout'
import { Actions, Mutations } from '@/store/modules/checkout'
import { Getter } from '@/decorators/Getter'
import { Action } from '@/decorators/Action'
import { Mutation } from '@/decorators/Mutation'
import ActionBar from '@/components/ActionBar.vue'
import Loadable from '@/components/Loadable.vue'
import MerchantLogo from '@/components/MerchantLogo.vue'
import StepLanding from '@/views/PaymentPage/StepLanding.vue'
import StepsLine from '@/components/StepsLine.vue'
import StepCustomerData from '@/views/PaymentPage/StepCustomerData.vue'
import StepPaymentMethod from '@/views/PaymentPage/StepPaymentMethod.vue'
import { ActionType, MutationType } from '@/types/general'
import { AxiosError } from 'axios'
import StepConfirmation from '@/views/PaymentPage/StepConfirmation.vue'
import { notification, NotificationPayloadType } from '@/services/notification'

@Component({
  name: 'PaymentPage',
  components: {
    StepConfirmation,
    StepPaymentMethod,
    StepCustomerData,
    StepsLine,
    Loadable,
    StepLanding,
    MerchantLogo,
    ActionBar,
  },
})
class PaymentPage extends Vue implements WithRoute {
  @Prop() private customerData!: CustomerDataType;
  @Getter('checkout/meta') meta!: CheckoutMetaType | null
  @Getter('checkout/data') checkoutData!: CustomerDataType | null
  @Getter('checkout/step') state!: CheckoutState

  @Action(`checkout/${Actions.GET_STEP}`) loadCheckoutData!: ActionType<string, Promise<CheckoutResponseType>>

  @Mutation(`checkout/${Mutations.SET_CHECKOUT_DATA}`) setState!: MutationType<CheckoutResponseType, void>
  @Mutation(`checkout/${Mutations.CLEANUP_INVALID}`) cleanupInvalids!: MutationType<void, void>

  isLoading = true
  editData: CustomerDataType | PaymentMethodResponseType | null = null
  customer: CustomerDataType | PaymentMethodResponseType | null = null
  is404 = false
  moto = false
  motoGlobal = false
  edit_customer = false;
  lang = 'en';
  customFaviconUrl = '/ratepay_favicon.png'
  customLogoUrl = ''
  customLogoPositionX = '0px'
  customLogoPositionY = '0px'

  onUpdate (newData:boolean) :void {
    this.moto = newData && this.motoGlobal
  }

  mounted (): void {
    this.isLoading = true
    localStorage.setItem('token', this.token)
    var checkoutDataString = localStorage.getItem('checkoutData') ?? '{}'
    this.customer = JSON.parse(checkoutDataString) ?? null
    this.loadCheckoutData(this.token)
      .then((checkoutResponse: CheckoutResponseType): void => {
        const data = checkoutResponse.data as CustomerDataType
        if (data !== null) {
          this.$set(this, 'editData', data)
        }
        var hasShipping = 'false'
        if (this.state === CheckoutState.LANDING_PAGE) {
          this.motoGlobal = data.moto
          localStorage.clear()
          localStorage.setItem('token', this.token)
          var lang = data.customer?.language || 'en'
          lang = lang.toLowerCase()
          localStorage.setItem('lang', lang)
          this.$store.dispatch('general/LOAD_LANGUAGE', lang, { root: true })
          this.$set(this, 'customer', data)

          // colors:
          data.custom_background_color = data.custom_background_color || '#fff'
          data.custom_background_color2 = data.custom_background_color2 || 'e4e8ee'
          data.custom_text_color = data.custom_text_color || '#000'
          data.custom_hyperlink_color = data.custom_hyperlink_color || '#e4003e'
          data.custom_font = data.custom_font || 'GT Walsheim, Roboto, Helvetica, Arial, sans-serif'
          data.custom_steps_font = data.custom_steps_font || 'GT Walsheim, Roboto, Helvetica, Arial, sans-serif'
          data.custom_steps_text_color = data.custom_steps_text_color || '#000'
          data.custom_logo_url = data.custom_logo_url || ''
          data.custom_logo_position_x = data.custom_logo_position_x || '0px'
          data.custom_logo_position_y = data.custom_logo_position_y || '0px'
          this.customLogoUrl = data.custom_logo_url
          this.customLogoPositionX = data.custom_logo_position_x
          this.customLogoPositionY = data.custom_logo_position_y
          data.custom_primary_button_font = data.custom_primary_button_font || 'Arial'
          data.custom_secondary_button_font = data.custom_secondary_button_font || 'Arial'
          data.custom_primary_button_background_color = data.custom_primary_button_background_color || '#e4003e'
          data.custom_secondary_button_background_color = data.custom_secondary_button_background_color || '#fff'
          data.custom_primary_button_text_color = data.custom_primary_button_text_color || '#fff'
          data.custom_secondary_button_text_color = data.custom_secondary_button_text_color || '#fff'
          data.custom_primary_button_border_color = data.custom_primary_button_border_color || '#e4003e'
          data.custom_secondary_button_border_color = data.custom_secondary_button_border_color || '#e4003e'
          this.customFaviconUrl = data.custom_favicon_url || '/ratepay_favicon.png'
          data.custom_steps_line_color = data.custom_steps_line_color || '#0a0082'
          data.custom_steps_unseen_line_color = data.custom_steps_unseen_line_color || '#9b9c9e'
          data.custom_steps_active_color = data.custom_steps_active_color || '#0a0082'
          data.custom_steps_active_text_color = data.custom_steps_active_text_color || '#fff'
          data.custom_steps_seen_color = data.custom_steps_seen_color || '#fff'
          data.custom_steps_seen_text_color = data.custom_steps_seen_text_color || '#0a0082'
          data.custom_steps_seen_border_color = data.custom_steps_seen_border_color || '#0a0082'
          data.custom_steps_unseen_color = data.custom_steps_unseen_color || '#e4e8ee'
          data.custom_steps_unseen_text_color = data.custom_steps_unseen_text_color || '#9b9c9e'
          data.custom_steps_unseen_border_color = data.custom_steps_unseen_border_color || '#9b9c9e'
          data.custom_shadow_color = data.custom_shadow_color || '#fff'
          data.custom_moto_background_color = data.custom_moto_background_color || '#fcf5ec'
          data.custom_moto_font_color = data.custom_moto_font_color || '#000'
          data.custom_moto_font = data.custom_moto_font || 'GT Walsheim, Roboto, Helvetica, Arial, sans-serif'
          localStorage.setItem('faviconurl', JSON.stringify(this.customFaviconUrl))
          localStorage.setItem('customlogourl', JSON.stringify(this.customLogoUrl))
          localStorage.setItem('customlogopositionx', this.customLogoPositionX)
          localStorage.setItem('customlogopositiony', this.customLogoPositionY)

          localStorage.setItem('full_data', JSON.stringify(data))
          localStorage.setItem('checkoutData', JSON.stringify(data))
          if (data.shipping_address?.city) {
            hasShipping = 'true'
          } else {
            hasShipping = 'false'
          }
          localStorage.setItem('hasShipping', hasShipping)
          if (typeof (data.billing_address) !== 'undefined') {
            localStorage.setItem('billing', JSON.stringify(data.billing_address))
          } else {
            localStorage.setItem('billing', JSON.stringify('{}'))
          }

          (window as any).di = {
            t: data.device.token,
            v: data.device.snippetId,
            l: data.device.location,
          }
          if (!this.motoGlobal) {
            const externalScript = document.createElement('script')
            externalScript.setAttribute('src', '//d.ratepay.com/' + data.device.snippetId + '/di.js')
            document.head.appendChild(externalScript)
          }
        } else if (this.state === CheckoutState.CUSTOMER_DETAILS) {
          this.$set(this, 'customer', data)
          localStorage.setItem('checkoutData', JSON.stringify(data))
          if (data.shipping_address?.city) {
            hasShipping = 'true'
          } else {
            hasShipping = 'false'
          }
          localStorage.setItem('hasShipping', hasShipping)
          if (typeof (data.billing_address) !== 'undefined') {
            localStorage.setItem('billing', JSON.stringify(data.billing_address))
          } else {
            localStorage.setItem('billing', JSON.stringify('{}'))
          }
        } else {
          this.edit_customer = !data.edit_customer
        }

        if (typeof (this.motoGlobal) === 'undefined') {
          localStorage.setItem('motoGlobal', 'false')
        } else {
          localStorage.setItem('motoGlobal', this.motoGlobal.toString())
        }

        this.customLogoUrl = data.custom_logo_url
        this.customLogoPositionX = data.custom_logo_position_x ?? '0%'
        this.customLogoPositionY = data.custom_logo_position_y ?? '0%'
        this.customFaviconUrl = data.custom_favicon_url

        var faviconurl = JSON.parse(localStorage.getItem('faviconurl'))
        if (faviconurl) {
          this.changeFavicon(faviconurl)
        } else {
          this.changeFavicon('/ratepay_favicon.png')
        }
      })
      .catch((error: AxiosError): void => {
        if (error?.response?.status === 404) {
          this.is404 = true

          return
        }
        console.log('Error loading checkout')
        console.log(error)
        notification.error(error as NotificationPayloadType)
      })
      .finally((): void => {
        this.isLoading = false
      })
  }

  get steps (): CheckoutState[] {
    return this.meta ? this.meta.states : []
  }

  get token (): string {
    return this.$route.params.token
  }

  get foo (): string | (string | null)[] {
    return this.$route.query.foo
  }

  get currentStep (): number {
    return this.meta ? this.meta.states.indexOf(this.state) : 1
  }

  get allowedMethods (): any {
    var hasShipping = localStorage.getItem('hasShipping') ?? 'false'
    var shipping = localStorage.getItem('shipping')
    var allowed_data = localStorage.getItem('allowed_methods')
    var shippingparsed : any
    var billingparsed : any
    if (shipping) {
      shippingparsed = JSON.parse(shipping)
    }
    if (shippingparsed && shippingparsed.city === '') {
      shippingparsed = false
    }
    if (allowed_data) {
      var allowedparsed = JSON.parse(allowed_data)
    }
    var billing = localStorage.getItem('billing') ?? '{}'
    billingparsed = JSON.parse(billing)

    var methodsObj = allowedparsed.allowed_methods
    const allowed: any[] = []

    var methodNames: any = Object.keys(methodsObj)
    var b2b_allowed: any = 'b2b_allowed'
    var delivery_adress_allowed: any = 'delivery_address_allowed'
    var country_code_billing: any = 'country_code_billing'
    var country_code_delivery: any = 'country_code_delivery'
    var is_moto: any = 'is_moto'

    methodNames.forEach((methodName: any) => {
      var moto = this.motoGlobal
      var removeMethod = false
      if (methodsObj[methodName][b2b_allowed].toString() === 'false' && allowedparsed.customer_type !== 'B2C') {
        removeMethod = true
      }

      if (methodsObj[methodName][delivery_adress_allowed].toString() === 'false' && hasShipping.toString() === 'true') {
        removeMethod = true
      }

      if (methodsObj[methodName][is_moto].toString() === 'false' && moto === true) {
        removeMethod = true
      }

      var bilcountries = methodsObj[methodName][country_code_billing].split(',')
      if (typeof (billingparsed.country_code) !== 'undefined') {
        if (!bilcountries.includes(billingparsed.country_code.toString())) {
          removeMethod = true
        }
      }

      var delcountries = methodsObj[methodName][country_code_delivery].split(',')
      if (hasShipping === 'true' && !delcountries.includes(shippingparsed.country_code.toString())) {
        removeMethod = true
      }

      if (!removeMethod) {
        allowed.push(methodName)
      }
    })

    return allowed
  }

  goBack (): void {
    this.isLoading = true
    this.go(goPreviousStep)
  }

  goNext (): void {
    this.isLoading = true

    if (this.state === CheckoutState.CHECKOUT_CONCLUSION) {
      confirmPayment(this.token)
        .then((response) => {
          EventBus.emit('payment-confirmed')

          return response
        })
        .then(({ data }) => {
          document.location.href = data
        })
        .catch(notification.error)
        .finally((): void => {
          this.isLoading = false
        })

      return
    }
    this.go(goNextStep)
  }

  go (callback: CallableFunction): Promise<void> {
    return callback(this.token, this.preparedRequestData)
      .then(({ data }: { data: CheckoutResponseType }) => {
        this.cleanupInvalids()
        this.setState(data)
        if (this.state === CheckoutState.PAYMENT_METHODS) {
          localStorage.setItem('allowed_methods', JSON.stringify(data.data))
        }
        this.$set(this, 'editData', { ...data.data })
      })
      .catch(notification.error)
      .finally((): void => {
        this.isLoading = false
      })
  }

  get actionbarConfig (): Record<CheckoutState, any> {
    return {
      [CheckoutState.LANDING_PAGE]: {
        back: false,
        next: this.$t('next'),
      },
      [CheckoutState.CUSTOMER_DETAILS]: {
        back: this.$t('back'),
        next: this.$t('next'),
      },
      [CheckoutState.PAYMENT_METHODS]: {
        back: this.$t('back'),
        next: this.$t('next'),
      },
      [CheckoutState.CHECKOUT_CONCLUSION]: {
        back: this.$t('back'),
        next: this.$t('action_pay'),
        showAgreement: true,
      },
    }
  }

  get preparedRequestData (): CustomerDataType | PaymentMethodResponseType | null {
    return this.editData
  }

  getImgUrl () :null|string {
    let url = JSON.parse(localStorage.getItem('customlogourl'))

    return url
  }

  getLogoPositionX () :string {
    let positionx = localStorage.getItem('customlogopositionx')
    if (typeof (positionx) === 'undefined') {
      return '0%'
    }
    if (positionx.search('%') === -1) {
      return positionx + '%'
    }

    return positionx
  }

  getLogoPositionY () :string {
    let positiony = localStorage.getItem('customlogopositiony')
    if (typeof (positiony) === 'undefined') {
      return '0%'
    }

    if (positiony.search('%') === -1) {
      return positiony + '%'
    }

    return positiony
  }

  changeFavicon (src: string) :void {
    let link = document.createElement('link')
    let oldLink = document.getElementById('dynamic-favicon')
    link.id = 'dynamic-favicon'
    link.rel = 'shortcut icon'
    link.href = src
    if (oldLink) {
      document.head.removeChild(oldLink)
    }
    document.head.appendChild(link)
  }

  cssVars ():any {
    var storage = localStorage.getItem('full_data') || '{}'
    var full_data = JSON.parse(storage)

    if (full_data) {
      return {
        '--button-primary-font': full_data['custom_primary_button_font'],
        '--button-secondary-font': full_data['custom_secondary_button_font'],
        '--button-primary-background-color': full_data['custom_primary_button_background_color'],
        '--button-secondary-background-color': full_data['custom_secondary_button_background_color'],
        '--button-primary-text-color': full_data['custom_primary_button_text_color'],
        '--button-secondary-text-color': full_data['custom_secondary_button_text_color'],
        '--button-primary-border-color': full_data['custom_primary_button_shadow_color'],
        '--button-secondary-border-color': full_data['custom_secondary_button_shadow_color'],
        '--custom-background-color': full_data['custom_background_color'],
        '--custom-background-color2': full_data['custom_background_color2'],
        '--custom-text-color': full_data['custom_text_color'],
        '--custom-sepa-text-color': full_data['custom_sepa_text_color'],
        '--custom-sepa-font': full_data['custom_sepa_font'],
        '--custom-hyperlink-color': full_data['custom_hyperlink_color'],
        '--custom-font': full_data['custom_font'],
        '--custom-menu-font': full_data['custom_menu_font'],
        '--custom-menu-text-color': full_data['custom_menu_text_color'],
        '--custom-logo-url': full_data['custom_logo_url'],
        '--custom-logo-position-x': full_data['custom_logo_position_x'],
        '--custom-logo-position-y': full_data['custom_logo_position_y'],
        '--custom-form-input-font': full_data['custom_form_input_font'],
        '--custom-form-label-font': full_data['custom_form_label_font'],
        '--custom-form-input-invalid-color': full_data['custom_form_input_invalid_color'],
        '--custom-moto-background-color': full_data['custom_moto_background_color'],
        '--custom-moto-font-color': full_data['custom_moto_font_color'],
        '--custom-moto-font': full_data['custom_moto_font'],
      }
    }
  }
}

const __default__ = PaymentPage
import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "122dcaeb-customLogoPositionX": (_vm.customLogoPositionX),
  "122dcaeb-customLogoPositionY": (_vm.customLogoPositionY)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__