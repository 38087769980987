var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "folder" }, [
    _c(
      "div",
      {
        staticClass: "expand-link",
        style: _vm.cssVars(),
        attrs: { tabindex: _vm.tabindex },
        on: {
          click: function ($event) {
            _vm.isExpanded = !_vm.isExpanded
          },
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [
                " ",
                "Spacebar",
              ]) &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            _vm.isExpanded = !_vm.isExpanded
          },
        },
      },
      [_vm._v(_vm._s(_vm.link))]
    ),
    _vm.isExpanded
      ? _c(
          "div",
          { staticClass: "expanded-content", style: _vm.cssVars() },
          [_vm._t("default")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }