var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "step-landing" }, [
    _c(
      "div",
      { staticClass: "basket-block" },
      [
        _c("Basket", {
          attrs: {
            basket: _vm.basket,
            "backgroundcolor2-data": _vm.backgroundcolor2Data,
          },
        }),
        _c("BuyerData", { attrs: { data: _vm.checkoutData } }),
        _c(
          "div",
          { staticClass: "payment-method-block" },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("confirmation_payment_method_title"))),
            ]),
            _c(
              "div",
              { staticClass: "payment-method-data" },
              [
                _c("PaymentMethodSelected", {
                  attrs: { data: _vm.checkoutData, profile: _vm.profile },
                }),
              ],
              1
            ),
            _vm.showInstallmentDetails
              ? _c("InstallmentCalculationsSummary", {
                  attrs: {
                    calculation: _vm.calculation,
                    currency: _vm.checkoutData.currency,
                    profile: _vm.profile,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }