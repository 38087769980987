
import { Component, Prop, Vue } from 'vue-property-decorator'
import { FullCheckoutDataType, PaymentDetailsType } from '@/types/checkout'
import helpers from '@/helpers'
import VIcon from '@/components/VIcon.vue'
import { PaymentMethod } from '@/constants/payment-method'

@Component({
  name: 'PaymentMethodSelected',
  components: { VIcon },
})
export default class PaymentMethodSelected extends Vue {
  @Prop() private data!: FullCheckoutDataType;

  getLabel (item : PaymentMethod) :string {
    return item.replace(/_[0-9]/g, '')
  }

  get details (): PaymentDetailsType {
    return this.data.payment_details
  }

  get amount (): string {
    return helpers.money(this.data.basket_amount, this.data.currency)
  }

  get months (): number|undefined {
    return this.details.installment?.total_months
  }

  get rate (): string {
    return helpers.money(this.details.installment?.plan.rate || 0, this.data.currency)
  }
}
