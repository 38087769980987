import MainLayout from '@/layouts/MainLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import { VueType } from '@/types/vue'

export const layouts = [
  MainLayout,
  EmptyLayout,
]

export const registerLayouts = (Vue: VueType): void => {
  layouts.forEach((layout: any) => {
    Vue.component(layout.extendOptions.name, layout)
  })
}
