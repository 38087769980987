import { http } from '@/api/http/http-provider'
import { AxiosPromise, AxiosResponse } from 'axios'

// @ts-ignore
const translationsUrl = window.config.VUE_APP_TRANSLATIONS_URL

const path = translationsUrl === 'local' ? '/translations' : ''

const requestSettings = {
  baseURL: translationsUrl === 'local' ? window.location.origin : translationsUrl,
  ignoreErrors: true,
}

const _getTranslationFile = (lang: string, hash: string) => {
  console.log('_getTranslationFile:')
  // perefer stored lang over param lang
  if (lang === 'en') {
    lang = localStorage.getItem('lang')
  }
  console.log('lang:' + lang)
  console.log('hash:' + hash)
  const preparedHash = hash ? `.${hash}` : ''

  return http.get(`${path}/${lang}${preparedHash}.json`, requestSettings)
}

export const getTranslations = (lang: string): AxiosPromise => http.get(`${path}/revisions.json?t=${new Date().getTime()}`, requestSettings)
  .then((resp: AxiosResponse) => _getTranslationFile(lang, resp.data?.[lang] ?? null))
  .then((translations: AxiosResponse) => translations.data)

export default {
  getTranslations,
}
