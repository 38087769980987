import { i18n, t } from '@/services/localizator'
import { getTranslations } from '@/api/translations'

const LOCALE_EN = 'en'
const LOCALE_DE = 'de'
const LOCALE_NL = 'nl'
const LOCALE_FR = 'fr'
export const supportedLocales = [LOCALE_EN, LOCALE_DE, LOCALE_NL, LOCALE_FR]

export enum Mutations {
  SET_TITLE = 'SET_TITLE',
  UPDATE_CURRENT_LANGUAGE = 'UPDATE_CURRENT_LANGUAGE',
}

export enum Actions {
  LOAD_LANGUAGE = 'LOAD_LANGUAGE',
}

const state = {
  title: t('page_title_default'),
  currentLanguage: LOCALE_EN,
}

const getters = {
  userLanguage (): string {
    const browserLanguage = navigator.language.split('-')[0]

    const language = localStorage.getItem('lang') || browserLanguage || LOCALE_EN
    if (!supportedLocales.includes(language)) {
      return LOCALE_EN
    }

    return language
  },
  languageOptions (): any {
    return [
      { value: LOCALE_EN, title: 'English' },
      { value: LOCALE_DE, title: 'Deutsch' },
      { value: LOCALE_NL, title: 'Nederlands' },
      { value: LOCALE_FR, title: 'Français' },
    ]
  },
  languageLoaded (state: State): boolean {
    return typeof i18n.messages[state.currentLanguage] !== 'undefined'
  },
}

const mutations = {
  [Mutations.SET_TITLE]: (state: State, title: string): void => {
    const documentTitle = title || t('page_title_default')
    state.title = documentTitle
    document.title = <string>i18n.t(documentTitle)
  },
  [Mutations.UPDATE_CURRENT_LANGUAGE] (state: State, language: string): void {
    state.currentLanguage = language
    document.documentElement.lang = language
    i18n.locale = language
  },
}

const actions = {
  async [Actions.LOAD_LANGUAGE] ({ commit, getters }: { commit: any, getters: any }, lang: string): Promise<void> {
    let locale = lang || getters.userLanguage
    let messages = {}
    if (!i18n.messages[locale]) {
      try {
        messages = await getTranslations(locale)
      } catch (e) {
        console.log('Exceptions:' + e)
        messages = {}
      }

      i18n.setLocaleMessage(locale, messages)
    }

    commit(Mutations.UPDATE_CURRENT_LANGUAGE, locale)
  },
}

export type State = typeof state
export type Getters = typeof getters

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
