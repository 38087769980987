
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'Folder',
})
export default class Alert extends Vue {
  @Prop({ required: true }) private link!: string
  @Prop({ default: 0 }) private tabindex!: string

  isExpanded = false

  cssVars ():any {
    var storage = localStorage.getItem('full_data') || '{}'
    var full_data = JSON.parse(storage)

    return {
      '--button-primary-background-color': full_data['custom_primary_button_background_color'],
      '--button-secondary-background-color': full_data['custom_secondary_button_background_color'],
      '--button-primary-text-color': full_data['custom_primary_button_text_color'],
      '--button-secondary-text-color': full_data['custom_secondary_button_text_color'],
      '--button-primary-border-color': full_data['custom_primary_button_shadow_color'],
      '--button-secondary-border-color': full_data['custom_secondary_button_shadow_color'],
      '--custom-background-color': full_data['custom_background_color'],
      '--custom-background-color2': full_data['custom_background_color2'],
      '--custom-text-color': full_data['custom_text_color'],
      '--custom-hyperlink-color': full_data['custom_hyperlink_color'],
      '--custom-font': full_data['custom_font'],
      '--custom-menu-font': full_data['custom_menu_font'],
      '--custom-logo-url': full_data['custom_logo-url'],
      '--custom-logo-position-x': full_data['custom_logo_position_x'],
      '--custom-logo-position-y': full_data['custom_logo_position_y'],
      '--custom-sepa-text-color': full_data['custom_sepa_text_color'],
      '--custom-sepa-font': full_data['custom_sepa_font'],
    }
  }
}
