import { VueType } from '@/types/vue'
import helpers from '@/helpers'
import { directive as onClickaway } from 'vue-clickaway'
import { CheckoutState } from '@/constants/checkout-state'
import { PaymentMethod } from '@/constants/payment-method'
import { Type as CustomerBusinessType } from '@/constants/customer'

export const setup = (Vue: VueType): void => {
  Vue.prototype.$enum = {
    CheckoutState,
    PaymentMethod,
    CustomerBusinessType,
  }

  Vue.prototype.$_ = helpers

  Vue.directive('onClickaway', onClickaway)
}
