import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'
import { Actions, Mutations } from '@/store/modules/general'
import NotFound from '@/views/NotFound.vue'
import PaymentPage from '@/views/PaymentPage.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/payment/:token',
    name: 'payment-page',
    component: PaymentPage,
    meta: {
      layout: 'MainLayout',
    },
  },
  { path: '*', component: NotFound, meta: { layout: 'EmptyLayout' } },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch(`general/${Actions.LOAD_LANGUAGE}`)
  store.commit(`general/${Mutations.SET_TITLE}`, to.meta?.title)

  next()
})

export default router
