
/**
 * It gives the included tax amount from a gross value considering
 * given tax rate.
 * Example:
 *    Gross Amount: 90.0 €
 *    Tax Rate: 19 %
 *    Included Tax Amount: 14.37 €
 */
export const includedTax = (grossAmount: number, taxRate: number): number => {
  return taxRate
}
