var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "notfound flex-center position-ref full-height" },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "notfoundtitle m-b-md" }, [
          _vm._v(" " + _vm._s(_vm.appName) + " "),
        ]),
        _c("div", { staticClass: "links" }, [
          _c("strong", [_vm._v(_vm._s(_vm.appVersion))]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }