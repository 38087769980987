
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'VButton',
})
export default class ActionBar extends Vue {
  @Prop({ default: '' }) private type!: string
  @Prop({ default: false }) private disabled!: boolean

  cssVars ():any {
    var storage = localStorage.getItem('full_data') || '{}'
    var full_data = JSON.parse(storage)

    return {
      '--button-primary-font': full_data['custom_primary_button_font'],
      '--button-secondary-font': full_data['custom_secondary_button_font'],
      '--button-primary-background-color': full_data['custom_primary_button_background_color'],
      '--button-secondary-background-color': full_data['custom_secondary_button_background_color'],
      '--button-primary-text-color': full_data['custom_primary_button_text_color'],
      '--button-secondary-text-color': full_data['custom_secondary_button_text_color'],
      '--button-primary-border-color': full_data['custom_primary_button_border_color'],
      '--button-secondary-border-color': full_data['custom_secondary_button_border_color'],
      '--custom-background-color': full_data['custom_background_color'],
      '--custom-background-color2': full_data['custom_background_color2'],
      '--custom-text-color': full_data['custom_text_color'],
      '--custom-font': full_data['custom_font'],
      '--custom-menu-font': full_data['custom_menu_font'],
      '--custom-logo-url': full_data['custom_logo-url'],
      '--custom-logo-position-x': full_data['custom_logo_position_x'],
      '--custom-logo-position-y': full_data['custom_logo_position_y'],
    }
  }

  onClick (e: Event): void {
    if (this.disabled) {
      return
    }
    this.$emit('click', e)
  }
}
