
import helpers from '@/helpers'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { InstallmentCalculationType } from '@/types/checkout'

@Component({
  name: 'InstallmentCalculationsSummary',
})
export default class InstallmentCalculationsSummary extends Vue {
  @Prop({ required: true }) private calculation!: InstallmentCalculationType
  @Prop({ required: true }) private currency!: string

  money (amount: number): string {
    return helpers.money(amount, this.currency)
  }
}
