var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "v-radio",
      class: {
        disabled: _vm.disabled,
        danger: _vm.danger,
        active: _vm.isChecked,
      },
    },
    [
      _c(
        "label",
        {
          staticClass: "v-radio-label",
          attrs: { for: _vm.computedId },
          on: { click: _vm.onClick },
        },
        [
          _c(
            "div",
            {
              staticClass: "radio-area",
              style: _vm.cssVars(),
              attrs: { tabindex: _vm.tabindex },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar",
                    ]) &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.onSelect.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "svg",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isChecked,
                      expression: "isChecked",
                    },
                  ],
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 29 29",
                    fill: "#0a0082",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z",
                    },
                  }),
                ]
              ),
              _c(
                "svg",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isChecked,
                      expression: "!isChecked",
                    },
                  ],
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 29 29",
                    fill: "#0a0082",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z",
                    },
                  }),
                ]
              ),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isChecked,
                    expression: "!isChecked",
                  },
                ],
                staticClass: "radio-placeholder",
                style: _vm.cssVars(),
              }),
            ]
          ),
          _vm.label
            ? _c(
                "div",
                {
                  staticClass: "radio-text",
                  style: _vm.cssVars(),
                  attrs: { title: _vm.label },
                },
                [_vm._v(_vm._s(_vm.label))]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selected,
                expression: "selected",
              },
            ],
            attrs: {
              type: "radio",
              id: _vm.computedId,
              disabled: _vm.disabled,
              tabindex: "-1",
            },
            domProps: {
              value: _vm.value,
              checked: _vm._q(_vm.selected, _vm.value),
            },
            on: {
              change: function ($event) {
                _vm.selected = _vm.value
              },
            },
          },
          "input",
          _vm.attrs,
          false
        )
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }