
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormGroupHint from '@/components/FormGroupHint.vue'

@Component({
  name: 'FormGroup',
  components: { FormGroupHint },
  inheritAttrs: false,
})
export default class FormGroup extends Vue {
  @Prop({ default: null }) private label!: string|null;
  @Prop({ default: true }) private show!: boolean;
  @Prop({ default: () => ({}) }) private model!: any;
  @Prop({ default: 1 }) private maxHints!: number;
  @Prop({ default: false }) private disabled!: boolean;
  @Prop({ default: () => [] }) private hints!: string[];

  get translations (): Record<string, any> {
    return {
      required: this.$t('validation_required_error'),
      requiredIf: this.$t('validation_required_error'),
      email: this.$t('validation_email_error'),
      sameAsPassword: this.$t('validation_password_not_match_error'),
      minLength: this.$t('validation_too_short_error'),
      maxLength: this.$t('validation_too_long_error'),
      minValue: this.$t('validation_incorrect_value_error'),
      maxValue: this.$t('validation_incorrect_value_error'),
      numeric: this.$t('validation_not_number_error'),
      money: this.$t('validation_incorrect_value_error'),
      integer: this.$t('validation_not_number_error'),
      date: this.$t('validation_date_error'),
      isIban: this.$t('validation_iban_error'),
      postalCode: this.$t('validation_postal_code_error'),
    }
  }

  cssVars ():any {
    var storage = localStorage.getItem('full_data') || '{}'
    var full_data = JSON.parse(storage)

    return {
      '--button-primary-background-color': full_data['custom_primary_button_background_color'],
      '--button-secondary-background-color': full_data['custom_secondary_button_background_color'],
      '--button-primary-text-color': full_data['custom_primary_button_text_color'],
      '--button-secondary-text-color': full_data['custom_secondary_button_text_color'],
      '--button-primary-border-color': full_data['custom_primary_button_shadow_color'],
      '--button-secondary-border-color': full_data['custom_secondary_button_shadow_color'],
      '--custom-background-color': full_data['custom_background_color'],
      '--custom-background-color2': full_data['custom_background_color2'],
      '--custom-text-color': full_data['custom_text_color'],
      '--custom-hyperlink-color': full_data['custom_hyperlink_color'],
      '--custom-font': full_data['custom_font'],
      '--custom-menu-font': full_data['custom_menu_font'],
      '--custom-logo-url': full_data['custom_logo-url'],
      '--custom-logo-position-x': full_data['custom_logo_position_x'],
      '--custom-logo-position-y': full_data['custom_logo_position_y'],
      '--custom-form-input-font': full_data['custom_form_input_font'],
      '--custom-form-label-font': full_data['custom_form_label_font'],
      '--custom-form-input-invalid-color': full_data['custom_form_input_invalid_color'],
    }
  }

  get isInvalid (): boolean {
    return this.show && this.messagesList.length > 0
  }

  get messagesList (): string[] {
    if (!this.model.$params) {
      return this.hints
    }

    return Object.keys(this.model.$params as Record<string, any>).reduce((messages, rule) => {
      if (typeof this.model[rule] !== 'undefined' && this.model[rule] === false) {
        messages.push(this.translations[rule] || this.$t(rule))
      }

      return messages
    }, [...this.hints]).slice(0, this.maxHints)
  }
}
