var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "v-checkbox",
      class: {
        disabled: _vm.disabled,
        danger: _vm.danger,
        active: _vm.isChecked,
      },
    },
    [
      _c(
        "label",
        {
          staticClass: "v-checkbox-label",
          class: { disabled: _vm.disabled },
          attrs: { for: _vm.computedId },
          on: { click: _vm.onClick },
        },
        [
          _c(
            "div",
            {
              staticClass: "checkbox-area",
              class: { checked: _vm.isChecked },
              style: _vm.cssVars(),
              attrs: { tabindex: _vm.tabindex },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar",
                    ]) &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.onClick.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "svg",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isChecked,
                      expression: "isChecked",
                    },
                  ],
                  attrs: {
                    width: "14",
                    height: "11",
                    viewBox: "0 0 14 11",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M1 5L5 9L13 1",
                      stroke: "black",
                      "stroke-width": "2",
                    },
                  }),
                ]
              ),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isChecked,
                    expression: "!isChecked",
                  },
                ],
                staticClass: "spacer",
              }),
            ]
          ),
          _vm.label
            ? _c(
                "div",
                {
                  staticClass: "check-text",
                  class: { disabled: _vm.disabled, oneline: _vm.oneline },
                  attrs: { title: _vm.label },
                },
                [_vm._v(_vm._s(_vm.label))]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selected,
                expression: "selected",
              },
            ],
            style: _vm.cssVars(),
            attrs: {
              tabindex: "-1",
              type: "checkbox",
              id: _vm.computedId,
              disabled: _vm.disabled,
            },
            domProps: {
              value: _vm.value,
              checked: Array.isArray(_vm.selected)
                ? _vm._i(_vm.selected, _vm.value) > -1
                : _vm.selected,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.selected,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = _vm.value,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.selected = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.selected = $$c
                }
              },
            },
          },
          "input",
          _vm.attrs,
          false
        )
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }