var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "step-customer-data" },
    [
      _c("h3", { staticClass: "subtitle" }, [
        _vm._v(_vm._s(_vm.$t("customer_data_title"))),
      ]),
      _c("CustomerForm", {
        model: {
          value: _vm.data.customer,
          callback: function ($$v) {
            _vm.$set(_vm.data, "customer", $$v)
          },
          expression: "data.customer",
        },
      }),
      _c("h3", { staticClass: "subtitle-address" }, [
        _vm._v(_vm._s(_vm.$t("address_title"))),
      ]),
      _c(
        "AddressForm",
        {
          attrs: { countries: _vm.data.country_code_billing },
          model: {
            value: _vm.data.billing_address,
            callback: function ($$v) {
              _vm.$set(_vm.data, "billing_address", $$v)
            },
            expression: "data.billing_address",
          },
        },
        [
          _c(
            "FormGroup",
            { staticClass: "has-shipping" },
            [
              _c("VCheckbox", {
                attrs: {
                  disabled: _vm.getDisabled(),
                  label: _vm.$t("input_different_shipping_address"),
                },
                model: {
                  value: _vm.hasShipping,
                  callback: function ($$v) {
                    _vm.hasShipping = $$v
                  },
                  expression: "hasShipping",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.hasShipping
        ? [
            _c("h4", { staticClass: "shipping-title" }, [
              _vm._v(_vm._s(_vm.$t("shipping_address_title"))),
            ]),
            _c("AddressForm", {
              staticClass: "shipping-form",
              attrs: { countries: _vm.data.country_code_delivery },
              model: {
                value: _vm.data.shipping_address,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "shipping_address", $$v)
                },
                expression: "data.shipping_address",
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }