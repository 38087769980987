
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  BasketItemType,
  BasketSpecialItemType,
  BasketType,
} from '@/types/checkout'
import { includedTax } from '@/services/tax-calculator'

@Component({
  name: 'Basket',
})
export default class Basket extends Vue {
  @Prop({ default: () => [] }) private basket!: BasketType;

  get items (): BasketItemType[] {
    return this.basket?.items || []
  }

  get discount (): BasketSpecialItemType|null {
    return this.basket.discount || null
  }

  get shipping (): BasketSpecialItemType|null {
    return this.basket.shipping || null
  }

  get itemsQuantity (): number {
    return this.items.reduce((result, item) => result + item.quantity, 0)
  }

  estimateTax (amount: number, rate: number): number {
    return includedTax(amount, rate)
  }

  estimateTotal (item: BasketItemType): number {
    if (typeof item.discount === 'undefined') {
      item.discount = 0
    }

    return item.quantity * (item.unit_price_gross + item.discount)
  }

  estimateArticle (item: BasketItemType): number {
    if (typeof item.discount === 'undefined') {
      item.discount = 0
    }

    return item.unit_price_gross + item.discount
  }

  cssVars ():any {
    var storage = localStorage.getItem('full_data') || '{}'
    var full_data = JSON.parse(storage)

    return {
      '--button-primary-background-color': full_data['custom_primary_button_background_color'],
      '--button-secondary-background-color': full_data['custom_secondary_button_background_color'],
      '--button-primary-text-color': full_data['custom_primary_button_text_color'],
      '--button-secondary-text-color': full_data['custom_secondary_button_text_color'],
      '--button-primary-border-color': full_data['custom_primary_button_border_color'],
      '--button-secondary-border-color': full_data['custom_secondary_button_border_color'],
      '--custom-oval-steps-text-color': full_data['custom_oval_steps_text_color'],
      '--custom-background-color': full_data['custom_background_color'],
      '--custom-background-color2': full_data['custom_background_color2'],
      '--custom-text-color': full_data['custom_text_color'],
      '--custom-font': full_data['custom_font'],
      '--custom-menu-font': full_data['custom_menu_font'],
      '--custom-logo-url': full_data['custom_logo-url'],
      '--custom-logo-position-x': full_data['custom_logo_position_x'],
      '--custom-logo-position-y': full_data['custom_logo_position_y'],
    }
  }
}
