
import { PaymentMethod } from '@/constants/payment-method'
import BuyerData from '@/views/PaymentPage/BuyerData.vue'
import InstallmentCalculationsSummary from '@/views/PaymentPage/InstallmentCalculationsSummary.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ActionBar from '@/components/ActionBar.vue'
import MerchantLogo from '@/components/MerchantLogo.vue'
import Basket from '@/components/Basket.vue'
import Address from '@/views/PaymentPage/Address.vue'
import {
  BasketType,
  FullCheckoutDataType,
  InstallmentCalculationType,
} from '@/types/checkout'
import PaymentMethodSelected from '@/views/PaymentPage/PaymentMethodSelected.vue'

@Component({
  name: 'StepConfirmation',
  components: {
    BuyerData,
    PaymentMethodSelected,
    Address,
    Basket,
    MerchantLogo,
    ActionBar,
    InstallmentCalculationsSummary,
  },
})
export default class StepConfirmation extends Vue {
  @Prop() private checkoutData!: FullCheckoutDataType;
  @Prop() private profile!: string;

  get basket (): BasketType|null|undefined {
    return this.checkoutData.basket
  }

  get showInstallmentDetails (): boolean {
    return [PaymentMethod.INSTALLMENT_BANK_TRANSFER, PaymentMethod.INSTALLMENT_DIRECT_DEBIT].includes(
      this.checkoutData.payment_details.payment_method,
    )
  }

  get calculation (): InstallmentCalculationType {
    return this.checkoutData?.payment_details?.installment?.plan as InstallmentCalculationType
  }
}
